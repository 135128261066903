import cFaq from '../components/c-faq';
//import cReviews from '../components/c-reviews';
import cAcquaintance from '../components/c-acquaintance';
import cCompany from '../components/c-company';
export default function () {
    console.log('solutions page');
    cFaq();
    cAcquaintance();
    //cReviews();
    cCompany();
}
