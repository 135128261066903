import cFaq from '../components/c-faq';
//import cReviews from '../components/c-reviews';
export default function () {
    console.log('pricing page');
    cFaq();
    //cReviews();

    document.addEventListener('DOMContentLoaded', function() {
        const textarea = document.getElementById('form-message');
        const countDisplay = document.querySelector('.form-message-count');
        const maxChars = 79;

        // Function to update the character counter
        const updateCounter = () => {
            const currentLength = textarea.value.length;
            countDisplay.textContent = `${currentLength}/80`;

            // If character count reaches the limit, add the .full class
            if (currentLength >= maxChars) {
                countDisplay.classList.add('full');
                textarea.value = textarea.value.substring(0, maxChars); // Enforce max length
            } else {
                countDisplay.classList.remove('full');
            }
        };

        // Listen for input events in the textarea
        textarea.addEventListener('input', updateCounter);
    });

    $(document).ready(function() {
        $(".js-select2").select2({
        //   placeholder: "Select an option",
          minimumResultsForSearch: Infinity,
          closeOnSelect: true
        });
      });

      function initStepForm(step1Selector, step2Selector, nextButtonSelector, backButtonSelector) {
        const step1 = document.querySelector(step1Selector);
        const step2 = document.querySelector(step2Selector);
        const nextButton = document.querySelector(nextButtonSelector);
        const backButton = document.querySelector(backButtonSelector);

        nextButton.addEventListener('click', function (event) {
            event.preventDefault();
            step1.classList.remove('active');
            step2.classList.add('active');
        });


        backButton.addEventListener('click', function (event) {
            event.preventDefault();
            step2.classList.remove('active');
            step1.classList.add('active');
        });
    }


    initStepForm('.step-1', '.step-2', '.btn-next--js', '.btn-back--js');

}
