import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default function () {
  gsap.registerPlugin(ScrollTrigger);
  // console.log('header');

  let body = document.querySelector("body");
  let window = window;
  let header = document.querySelector(".c-header--js");
  let mobileMenuBtn = header.querySelector(".btn-menu--js");
  let mobileMenuBg = header.querySelector(".c-header-mobile-bg--js");
  let mobileMenu = header.querySelector(".c-header-mobile--js");
  let mobileMenuClose = header.querySelector(".btn-menu-close--js");
  let mobileMenuWrap = header.querySelector(".c-header-mobile-wrap");

  ScrollTrigger.create({
    trigger: document.body,
    start: 'top -80',
    markers: false,
    toggleClass: { className: 'header-fixed', targets: '.c-header' },
  });


  function mobileMenuFunc() {
    let modalMenuAnim = gsap.timeline({ paused: true })
      .to(mobileMenu, { duration: 0.3, autoAlpha: 1 })
      .from(mobileMenuWrap, { duration: 0.3, xPercent: 100 }, '<');

    mobileMenuBtn.addEventListener("click", function () {
      if (!mobileMenuBtn.classList.contains('open')) {
        header.classList.remove('open-submenu', 'page-fixed');
        body.classList.add("opened-mobile-menu", "page-fixed");
        mobileMenu.classList.add("open");
        mobileMenuBtn.classList.add("open");
        mobileMenuBg.classList.add("open");
        modalMenuAnim.play();
      } else {
        mobileMenuBtn.classList.remove("open");
        body.classList.remove("opened-mobile-menu", "page-fixed");
        mobileMenu.classList.remove("open");
        mobileMenuBtn.classList.remove("open");
        mobileMenuBg.classList.remove('open');
        modalMenuAnim.reverse();
      }
    });

  }

  mobileMenuFunc();

  if (document.querySelectorAll('.c-mob-menu-gsap--js').length > 0) {
    document.querySelectorAll('.c-mob-item-title').forEach(title => {
      title.addEventListener('click', function () {
        let tabItem = this.closest('.c-mob-item');
        let tabItemContent = tabItem.querySelector(".c-mob-item-block");

        if (tabItemContent.offsetHeight !== 0) {
          gsap.timeline().to(tabItemContent, 0.3, {
            height: 0,
            onUpdate: () => {
              document.body.offsetHeight;
            }
          });
          tabItem.classList.remove("active");
        } else {
          gsap.timeline().to(tabItemContent, 0.3, {
            height: "auto",
            onUpdate: () => {
              document.body.offsetHeight;
            }
          });
          tabItem.classList.add("active");
        }
      });
    });
  }


  function updateTabs() {
    const tabs = document.querySelectorAll('.c-acquaintance-tabs-tab--js');
    moveContentAtAIMLPage(tabs);
    moveUnderlineAtAIMLPage(tabs);

  }
  function moveContentAtAIMLPage(tabs) {
    const selectedTab = sessionStorage.getItem('selectedTab');
    const projects = document.querySelectorAll('.c-acquaintance-item--js');

    // Initially activate the first tab and corresponding project
    let defaultTabIndex = 0;

    if (selectedTab) {
      tabs.forEach((tab, index) => {
        if (tab.getAttribute('data-category') === selectedTab) {
          defaultTabIndex = index;
        }
      });
    }

    tabs.forEach(tab => tab.classList.remove('active'));
    projects.forEach(project => project.classList.remove('active'));

    tabs[defaultTabIndex].classList.add('active');
    projects.forEach(project => {
      if (project.getAttribute('data-category') === tabs[defaultTabIndex].getAttribute('data-category')) {
        project.classList.add('active');
      }
    });
  }
  function moveUnderlineAtAIMLPage(tabs) {
    const underline = document.querySelector('.underline');

    function moveUnderline(tab) {
      const tabRect = tab.getBoundingClientRect();
      const tabsBlockRect = tab.parentElement.getBoundingClientRect();

      gsap.to(underline, {
        x: tabRect.left - tabsBlockRect.left,
        width: tabRect.width,
        duration: 0.3,
        ease: 'power2.out'
      });
    }

    tabs.forEach(tab => {
      tab.addEventListener('click', function () {
        tabs.forEach(t => t.classList.remove('active'));
        tab.classList.add('active');

        moveUnderline(tab);
      });
    });

    const activeTab = document.querySelector('.c-acquaintance-tabs-tab.active');
    if (activeTab) {
      moveUnderline(activeTab);
    }
  }
  document.addEventListener('DOMContentLoaded', (event) => {
    document.storeTab = function (tabName) {
      sessionStorage.setItem('selectedTab', tabName);
      updateTabs();
    };
  });
}
