import Player from '@vimeo/player';

export default function () {
    document.addEventListener('DOMContentLoaded', function () {
        // Get all video containers
        const videoContainers = document.querySelectorAll('.video-container');

        // Initialize a map to keep track of all Vimeo players
        const players = new Map();

        // Loop through each video container
        videoContainers.forEach(function (container) {
          const previewContainer = container.querySelector('.video-preview');
          const playButton = container.querySelector('.play-button');
          const vimeoPlayerContainer = container.querySelector('.vimeo-player');
          const videoId = vimeoPlayerContainer.getAttribute('data-video-id');

          // Initialize Vimeo Player for this container
          const vimeoPlayer = new Player(vimeoPlayerContainer, {
            id: videoId,
            width: '100%',
            autoplay: false,  // Start video only on play button click
          });

          // Store the player in the map
          players.set(videoId, vimeoPlayer);

          // Handle play button click for this video
          playButton.addEventListener('click', function () {
            // Pause all other videos
            players.forEach((player, id) => {
              if (id !== videoId) {
                player.pause();
              }
            });

            // Hide the preview and play button
            previewContainer.classList.add('hidden');
            // Start playing the video
            vimeoPlayer.play();
          });
        });
    });
}
