import Slick from 'slick-carousel';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import globalScripts from './globalScripts';
import home from './pages/p-home';
import aiml from './pages/p-aiml';
import solutions from './pages/p-solutions';
import industry from './pages/p-industry';
import about from './pages/p-about';
import client from './pages/p-client';
import contact from './pages/p-contact';
import casePage from './pages/p-case';
import pricing from './pages/p-pricing';
globalScripts();


if (document.getElementById('page-home')) {
    home();
}
if (document.getElementById('page-aiml')) {
    aiml();
}
if (document.getElementById('page-solutions')) {
    solutions();
}

if (document.getElementById('page-about')) {
    about();
}
if (document.getElementById('page-client')) {
    client();
}
if (document.getElementById('page-contact')) {
    contact();
}
if (document.getElementById('page-case')) {
    casePage();
}
if (document.getElementById('page-pricing')) {
    pricing();
}
// because 8 pages industry
if (document.querySelector('.p-industry')) {
    industry();
}
