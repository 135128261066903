global.$ = global.jQuery = require('jquery');
import header from './components/c-header';
import modal from './components/c-modal';
import form from './components/c-form';
import video from './components/c-vimeo';
export default function () {
	header();
	modal();
	form();
	video();
}
