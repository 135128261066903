export default function () {
    let $body = $("body");
    let $cCompany = $body.find('.c-company--js'),
        $cCompanySlider = $cCompany.find('.c-company-slider--js'),
        $cCompanySliderPrev =  $cCompany.find('.arrow-btn-prev'),
        $cCompanySliderNext =  $cCompany.find('.arrow-btn-next');
    if ($cCompany.length > 0) {
        $cCompanySlider.slick({
            dots: true,
            arrows: true,
            nextArrow: $cCompanySliderNext,
            prevArrow: $cCompanySliderPrev,
            appendDots: $('.c-company--js .slick-dots-wrap-js'),
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 1000,
            cssEase: 'cubic-bezier(0.65, 0.05, 0.36, 1)',
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        centerMode: true,
                        // customPadding: 0,

                    },
                },
                {
                    breakpoint: 820,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        // customPadding: 0,
                    },
                },
            ],
        });
    }
}
