import cFaq from '../components/c-faq';
export default function () {
    console.log('contact page');
    cFaq();

    document.addEventListener('DOMContentLoaded', function() {
        const textarea = document.getElementById('form-message');
        const countDisplay = document.querySelector('.form-message-count');
        const maxChars = 79;

        // Function to update the character counter
        const updateCounter = () => {
            const currentLength = textarea.value.length;
            countDisplay.textContent = `${currentLength}/80`;

            // If character count reaches the limit, add the .full class
            if (currentLength >= maxChars) {
                countDisplay.classList.add('full');
                textarea.value = textarea.value.substring(0, maxChars); // Enforce max length
            } else {
                countDisplay.classList.remove('full');
            }
        };

        // Listen for input events in the textarea
        textarea.addEventListener('input', updateCounter);
    });
}
