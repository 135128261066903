export default function () {
    document.addEventListener("DOMContentLoaded", function() {
        const consultationForm = document.getElementById('consultation-form');
        if (consultationForm) {
            consultationForm.addEventListener('submit', function(event) {
                event.preventDefault();
                console.log("consultation-form");

            });
        }

        const contactUsForm = document.getElementById('contact-us-form');
        if (contactUsForm) {
            contactUsForm.addEventListener('submit', function(event) {
                event.preventDefault();
                console.log("contact-us-form");
            });
        }


        const contactForm = document.getElementById('contact-form');
        if (contactForm) {
            contactForm.addEventListener('submit', function(event) {
                event.preventDefault();
                console.log("contact-form");
            });
        }

        const downloadForm = document.getElementById('download-form');
        if (downloadForm) {
            downloadForm.addEventListener('submit', function(event) {
                event.preventDefault();
                console.log("download-form");
            });
        }

        const discountForm = document.getElementById('discount-form');
        if (discountForm) {
            discountForm.addEventListener('submit', function(event) {
                event.preventDefault();
                console.log("discount-form");
            });
        }

        const pricingForm = document.getElementById('pricing-form');
        if (pricingForm) {
            pricingForm.addEventListener('submit', function(event) {
                event.preventDefault();
                console.log("pricing-form");
            });
        }
    });

}
