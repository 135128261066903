import { gsap } from "gsap";
export default function () {
    console.log('acquaintance component');
    if (document.querySelectorAll('.c-acquaintance--js').length > 0) {

        document.addEventListener('DOMContentLoaded', function() {
          const tabs = document.querySelectorAll('.c-acquaintance-tabs-tab--js');
          const projects = document.querySelectorAll('.c-acquaintance-item--js');
          const selectedTab = sessionStorage.getItem('selectedTab');
        
          // Initially activate the first tab and corresponding project
          let defaultTabIndex = 0;
        
          if (selectedTab) {
            tabs.forEach((tab, index) => {
              if (tab.getAttribute('data-category') === selectedTab) {
                defaultTabIndex = index;
              }
            });
          }
        
          tabs.forEach(tab => tab.classList.remove('active'));
          projects.forEach(project => project.classList.remove('active'));
        
          tabs[defaultTabIndex].classList.add('active');
          projects.forEach(project => {
            if (project.getAttribute('data-category') === tabs[defaultTabIndex].getAttribute('data-category')) {
              project.classList.add('active');
            }
          });
        
          tabs.forEach(tab => {
            tab.addEventListener('click', function() {
              const category = tab.getAttribute('data-category');
        
              // Remove active class from all tabs and items
              tabs.forEach(t => t.classList.remove('active'));
              projects.forEach(project => project.classList.remove('active'));
        
              // Add active class to clicked tab and corresponding project
              tab.classList.add('active');
              projects.forEach(project => {
                if (project.getAttribute('data-category') === category) {
                  project.classList.add('active');
                }
              });
        
              // Store the selected tab in session storage
              storeTab(category);
            });
          });

        });
        document.addEventListener('DOMContentLoaded', function() {
            const tabs = document.querySelectorAll('.c-acquaintance-tabs-tab--js');
            const underline = document.querySelector('.underline');

            function moveUnderline(tab) {
              const tabRect = tab.getBoundingClientRect();
              const tabsBlockRect = tab.parentElement.getBoundingClientRect();

              gsap.to(underline, {
                x: tabRect.left - tabsBlockRect.left,
                width: tabRect.width,
                duration: 0.3,
                ease: 'power2.out'
              });
            }

            tabs.forEach(tab => {
              tab.addEventListener('click', function() {
                tabs.forEach(t => t.classList.remove('active'));
                tab.classList.add('active');

                moveUnderline(tab);
              });
            });

            const activeTab = document.querySelector('.c-acquaintance-tabs-tab.active');
            if (activeTab) {
              moveUnderline(activeTab);
            }
          });
    }
}
