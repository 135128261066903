import { gsap } from "gsap";

export default function () {

	if (document.querySelectorAll('.c-faq-list-item-gsap--js').length > 0) {
		document.querySelectorAll('.c-tab-item-title').forEach(title => {
			title.addEventListener('click', function () {
				let tabItem = this.closest('.c-tab-item');
				let tabItemContent = tabItem.querySelector(".c-tab-item-block");

				// gsap.timeline().to(document.querySelectorAll('.c-tab-item-block'), 0.3, { height: 0 });
				// document.querySelectorAll('.c-tab-item').forEach(item => item.classList.remove("active"));

				if (tabItemContent.offsetHeight !== 0) {
					gsap.timeline().to(tabItemContent, 0.3, {
						height: 0,
						onUpdate: () => window.dispatchEvent(new Event('resize'))
					});
					tabItem.classList.remove("active");
				} else {
					gsap.timeline().to(tabItemContent, 0.3, {
						height: "auto",
						onUpdate: () => window.dispatchEvent(new Event('resize'))
					});
					tabItem.classList.add("active");
				}
			});
		});
	}

}
