export default function () {
    let body = document.body,
        el,
        btnModalThis,
        descInfo,
        btnModal = document.querySelectorAll('.btn-modal--js'),
        windowModal = document.querySelector('.section-modal--js'),
        modalBlocks = document.querySelectorAll('.modal-block--js'),
        btnClose = document.querySelectorAll('.btn-modal-close--js'),
        modalBg = document.querySelectorAll('.modal-bg--js');
        // modalDiscount = document.getElementById('c-modal-discount'),
        // btnCloseDiscount = modalDiscount.querySelector('.btn-modal-close--js'),
        // modalBgDiscount = modalDiscount.querySelector('.modal-bg--js');

    function blockModal() {
        console.log('modal block');
        btnModal.forEach(button => {
            button.addEventListener('click', function () {
                btnModalThis = this;
                descInfo = btnModalThis.getAttribute('data-title');
                modalBlocks.forEach(modalBlock => {
                    let input = modalBlock.querySelector('input.title-hidden-input');
                    if (input) input.value = descInfo;
                });

                body.classList.remove('open-modal');
                btnModal.forEach(btn => btn.classList.remove('btn-active'));
                windowModal.classList.remove('show-modal');
                modalBlocks.forEach(modal => modal.classList.remove('open'));
                modalBg.forEach(bg => bg.classList.remove('open'));

                this.classList.add('btn-active');
                el = this.getAttribute('data-modal');
                body.classList.add('open-modal');
                windowModal.classList.add('show-modal');
                modalBlocks.forEach(modalBlock => {
                    if (modalBlock.getAttribute('data-modal') === el) {
                        modalBlock.classList.add('open');
                    }
                });
                modalBg.forEach(bg => bg.classList.add('open'));
            });
        });

        btnClose.forEach(button => {
            button.addEventListener('click', function () {
                body.classList.remove('open-modal');
                btnModal.forEach(btn => btn.classList.remove('btn-active'));
                windowModal.classList.remove('show-modal');
                modalBlocks.forEach(modal => modal.classList.remove('open'));
                modalBg.forEach(bg => bg.classList.remove('open'));
            });
        });

        modalBg.forEach(bg => {
            bg.addEventListener('click', function () {
                body.classList.remove('open-modal');
                btnModal.forEach(btn => btn.classList.remove('btn-active'));
                windowModal.classList.remove('show-modal');
                modalBlocks.forEach(modal => modal.classList.remove('open'));
                modalBg.forEach(bg => bg.classList.remove('open'));
            });
        });
    }
    blockModal();
    // banner 2 sec
    // localStorage management
    // if (!localStorage.getItem('emailconfirmed')) {
    //     localStorage.setItem('emailconfirmed', 'false');
    // }
    // if (!localStorage.getItem('modaldiscount')) {
    //     localStorage.setItem('modaldiscount', 'false');
    // }
    // window.addEventListener('beforeunload', function () {
    //     localStorage.removeItem('emailconfirmed');
    //     localStorage.removeItem('modaldiscount');
    // });

    // function openModal() {
    //     body.classList.add('open-modal');
    //     modalDiscount.classList.add('open');
    //     modalDiscount.parentElement.classList.add('show-modal');
    // }

    // function closeModal() {
    //     body.classList.remove('open-modal');
    //     modalDiscount.classList.remove('open');
    //     modalDiscount.parentElement.classList.remove('show-modal');
    //     localStorage.setItem('modaldiscount', 'true');
    // }

    // btnCloseDiscount.addEventListener('click', closeModal);
    // modalBgDiscount.addEventListener('click', closeModal);

    // let leaveTimer;
    // document.addEventListener('mouseleave', function () {
    //     leaveTimer = setTimeout(function () {
    //         if (!document.body.classList.contains('open-modal') && localStorage.getItem('emailconfirmed') === 'false' && localStorage.getItem('modaldiscount') === 'false') {
    //             openModal();
    //         }
    //     }, 3000);
    // });

    // document.addEventListener('mouseenter', function () {
    //     clearTimeout(leaveTimer);
    // });
}
